<!-- 卡片信息页面 -->
<template>
  <div class="wrap">
    <div class="contentTop">
      <div class="qrContent positionCenter">
        <div class="qrcanvas" @click="getCardData">
          <div class="qrcode-box">
            <div class="qrcode-img">
              <img id="qrCodeIco" src="@/assets/img/logo_.png" />
            </div>
          </div>
        </div>
        <p class="qrText">就诊时出示此二维码</p>
      </div>
    </div>
    <div class="contentBotton">
      <p class="formText">
        <span>姓名</span>
        <span>{{ cardItem.name }}</span>
      </p>
      <p class="formText">
        <span>身份证号</span>
        <span>
          <encryption-component :textNum="cardItem.idNumber" :start="4" />
        </span>
      </p>
      <p class="formText">
        <span>手机号</span>
        <span>
          <encryption-component :textNum="cardItem.phone" :start="3" />
        </span>
      </p>
    </div>
    <van-button type="primary" class="enterJZ" @click="routerTo"
      >进入就诊人管理</van-button
    >
    <van-button
      type="primary"
      class="setJZ"
      v-if="cardItem.deafult == false"
      @click="setDefault"
      >设为默认就诊人</van-button
    >
    <van-button type="primary" disabled class="setJZ-disabled" v-else
      >设为默认就诊人</van-button
    >
    <span class="unbound" @click="unbindHealthCard">解除绑定>></span>
    <!-- <a href="javasctipt;">
      <p class="psText colorhui">解绑电子健康卡</p>
    </a> -->
    <van-overlay :show="overlayState">
      <van-loading type="spinner" color="#1989fa">加载中...</van-loading>
    </van-overlay>
  </div>
</template>

<script>
import QRCode from "qrcodejs2";
import { Toast } from "vant";
import encryptionComponent from "../../components/util/encryptionComponent";
export default {
  components: { encryptionComponent },
  data() {
    return {
      cardItem: {},
      qrCodeText: "",
      overlayState: false,
    };
  },
  created() {
    this.cardItem = JSON.parse(sessionStorage.getItem("card_cardItem"));
    this.getCardData();
  },
  methods: {
    // 接收通过卡列表或者绑定流程传递过来的qrCodeText，
    async getCardData() {},
    // 进入就诊人管理
    routerTo() {
      this.$router.push("/patient");
    },
    // 设置默认就诊人
    setDefault() {
      let postData = {
        healthid: this.cardItem.healthId,
        userid: sessionStorage.getItem("userid"),
      };
      this.$http
        .put("/api/card/default", postData)
        .then((res) => {
          if (res.status == 200) {
            Toast({
              message: "设置成功！",
              duration: 500,
              onClose: () => {
                this.$router.push("/patient");
              },
            });
          } else {
            Toast.fail({ message: "设置失败！" });
          }
        })
        .catch((err) => {
          Toast.fail({ message: err.response.data });
          console.log(err);
        });
    },
    /*
      解除电子健康卡绑定
    */
    unbindHealthCard() {
      if (this.cardItem.deafult == true) {
        Toast({ message: "默认就诊人不能解除绑定" });
        return;
      }
      let postData = {
        healthid: this.cardItem.healthId,
        userid: sessionStorage.getItem("userid"),
      };
      this.$http
        .put("/api/card/unbind", postData)
        .then((res) => {
          if (res.status == 200) {
            Toast({
              message: "解绑成功！",
              duration: 500,
              onClose: () => {
                this.$router.push("/patient");
              },
            });
          } else {
            Toast.fail({ message: "解绑失败！" });
          }
        })
        .catch((err) => {
          Toast.fail({ message: err.response.data });
          console.log(err);
        });
    },
  },
  mounted() {
    var deviceWidth = document.documentElement.clientWidth || window.innerWidth;
    var qrcode = new QRCode(document.querySelector(".qrcode-img"), {
      text: this.cardItem.patId,
      width: deviceWidth * 0.6,
      height: deviceWidth * 0.6,
      colorDark: "#000000",
      colorLight: "#ffffff",
      correctLevel: QRCode.CorrectLevel.H,
    });
  },
};
</script>
<style scoped>
.contentTop {
  width: 9rem;
  margin: auto;
  margin-top: 0.5rem;
  background-color: #fff;
  border-radius: 0.4rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  -webkit-box-shadow: #e6e6e6 0px 0px 10px;
  -moz-box-shadow: #e6e6e6 0px 0px 10px;
  box-shadow: #e6e6e6 0px 0px 10px;
  border-radius: 5px;
}
.qrcanvas {
  position: relative;
}
#qrCodeIco {
  position: absolute;
  width: 1.5rem;
  height: 1.5rem;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 3px solid #fff;
  background-color: #fff;
  border-radius: 10px;
}
.QRlogo {
  width: 2rem;
  height: 2rem;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.qrText {
  height: 0.5rem;
  line-height: 0.5rem;
  font-size: 0.2rem;
  color: rgb(170, 170, 170);
}
.contentBotton {
  width: 9rem;
  margin: auto;
  border-radius: 0.4rem;
  margin-top: 0.5rem;
  overflow: hidden;
  -webkit-box-shadow: #e6e6e6 0px 0px 10px;
  -moz-box-shadow: #e6e6e6 0px 0px 10px;
  box-shadow: #e6e6e6 0px 0px 10px;
  border-radius: 5px;
}
.formText {
  height: 1rem;
  line-height: 1rem;
  font-size: 0.4rem;
  font-weight: bold;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  padding: 0.1rem 0.7rem;
}
.enterJZ {
  margin: auto;
  border-radius: 5px;
  position: relative;
  width: 4rem;
  left: -0.3rem;
  border: none;
  background-image: linear-gradient(to right, #57fdc4, #00d48b);
}
.setJZ {
  margin: auto;
  border-radius: 5px;
  position: relative;
  width: 4rem;
  left: 0.3rem;
  border: none;
  background-image: linear-gradient(to right, #6dc0ff, #189bff);
}
.setJZ-disabled {
  margin: auto;
  border-radius: 5px;
  position: relative;
  width: 4rem;
  left: 0.3rem;
  border: none;
  background-color: #afafaf;
}
.unbound {
  display: block;
  margin: 0.5rem auto;
  color: #44aefe;
  border: none;
  font-size: 0.4rem;
}
.qrcode-img {
  height: 6rem;
  width: 6rem;
  margin: auto;
  position: relative;
}
</style>
